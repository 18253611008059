<template>
	<div class="view view--single single">
		<main-header />
		<router-view class="single__content container" />
		<main-footer />
	</div>
</template>

<script>
import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'

export default {
	name: 'viewSingle',
	components: {
		MainFooter,
		MainHeader,
	},
}
</script>

<style lang="scss" scoped>
.single {

	&__content {
		margin-top: 10rem;
	}
}
</style>
